<template>
  <div
    class="navbar-item is-hidden-tablet-only is-hidden-desktop-only"
    v-if="isUser"
  >
    <a
      class="nav-menu-item"
      @click="switchBranch('button')"
      data-test-id="branch-switch-button"
    >
      <strong>{{ branchName }}</strong>
      <span class="icon">
        <i class="fad fa-exchange" />
      </span>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import truncate from 'truncate'
export default {
  name: 'AppHeaderEndSwitch',
  computed: {
    ...mapGetters('auth', ['isUser', 'branch']),
    branchName() {
      const length = this.$experian ? 18 : 40
      return truncate(this.branch.name, length)
    }
  },
  methods: {
    track(action) {
      this.$mxp.track(`app_header_${action}`)
    },
    switchBranch(source) {
      this.track(`open_switch_branch_modal${source}`)
      this.$modal.open('account/AccountSwitch')
    }
  }
}
</script>
